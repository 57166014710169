import { Card } from "@clipboard-health/ui-components";
import { Image, Text } from "@clipboard-health/ui-react";
// TODO: Use our own Avatar
// eslint-disable-next-line no-restricted-imports
import { Avatar, Box, CardContent, Skeleton, Stack } from "@mui/material";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { format, parseISO } from "date-fns";
import type React from "react";
import { type EveryMessage } from "sendbird-uikit";

import { transformPlacementData, useGetPlacement } from "../../Placements/api/useGetPlacement";
import {
  type ChatMessageWithText,
  isChatMessageWithText,
  isSendBirdGroupChannel,
  type PlacementChatMetadata,
} from "../types";

function PlacementChatMessageContent({
  chatMessage,
  chatChannel,
}: {
  chatMessage: ChatMessageWithText;
  chatChannel: SendBird.GroupChannel;
}) {
  const { userId: workerId } = useDefinedWorker();
  const { facilityName, placementTitle, placementId } =
    chatChannel.getCachedMetaData() as PlacementChatMetadata;

  const { data: placement, status } = useGetPlacement({
    placementId: placementId ?? "",
    workerId,
  });

  if (!chatMessage.message || !placementId) {
    return undefined as unknown as React.ReactElement;
  }

  const transformedPlacement = placement ? transformPlacementData(placement) : undefined;
  const defaultMessage =
    facilityName && placementTitle
      ? `Congrats! You've successfully applied for the ${placementTitle} opening at ${facilityName}.\n\nIf the facility likes your profile they'll reach out to you on this channel to set up an interview!\n\nYou can also use this channel to message the facility directly about anything related to their job listing.`
      : chatMessage.message;

  const isWorkplaceProspect = Boolean(
    transformedPlacement?.workplace.placementSettings?.isProspect
  );

  const interview = transformedPlacement?.interviews.find(
    (interview) => interview.attributes.status === InterviewStatus.BOOKED
  );
  const messageToDisplay =
    transformedPlacement &&
    interview &&
    facilityName &&
    placementTitle &&
    transformedPlacement.address.formatted
      ? `Thank you for booking ${
          isWorkplaceProspect ? "a walk-in slot" : "an interview"
        } for the ${placementTitle} opening at ${facilityName}.\n\nYour ${
          isWorkplaceProspect ? "walk-in slot" : "interview"
        } is scheduled for ${format(
          parseISO(interview.attributes.start),
          "MM/dd/yyyy"
        )} at ${format(parseISO(interview.attributes.start), "h:mm a")}.\n\nLocation: ${
          transformedPlacement.address.formatted
        }`
      : defaultMessage;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={2}
      sx={{ py: 5 }}
    >
      <Avatar
        alt="Clipboard"
        sx={{
          width: 28,
          height: 28,
        }}
      >
        <Image src={cbhBunnyLogo} alt="Clipboard" width={18} height={18} />
      </Avatar>
      <Stack spacing={1}>
        <Box sx={{ px: 2 }}>
          <Text bold variant="body2" sx={{ color: "text.primary" }}>
            Clipboard
          </Text>
        </Box>
        <Card variant="primary">
          <CardContent>
            <Text variant="subtitle2" sx={{ color: "text.primary", whiteSpace: "pre-wrap" }}>
              {status === "loading" && <Skeleton variant="text" width="100%" height="24px" />}
              {status === "success" && messageToDisplay.trim()}
              {status === "error" && defaultMessage.trim()}
            </Text>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  ) as unknown as React.ReactElement;
}

export function PlacementChatMessage({
  message,
  channel,
}: {
  message: EveryMessage;
  channel: unknown;
}) {
  const chatMessage = (
    typeof message === "object" && message !== null ? message : JSON.parse(JSON.stringify(message))
  ) as unknown;

  const chatChannel = (
    typeof channel === "object" && channel !== null ? channel : JSON.parse(JSON.stringify(channel))
  ) as unknown;

  if (!isChatMessageWithText(chatMessage) || !isSendBirdGroupChannel(chatChannel)) {
    return null;
  }

  if (
    chatMessage.customType === "PLACEMENT_APPLICATION" &&
    chatChannel.customType === "placements"
  ) {
    return <PlacementChatMessageContent chatMessage={chatMessage} chatChannel={chatChannel} />;
  }

  // the message user could be the managing (parent) facility, but this would be confusing for users
  // so we overwrite the name to show the true facility name
  const placementChatMetadata = chatChannel.getCachedMetaData() as PlacementChatMetadata;
  if (message?._sender && placementChatMetadata.facilityName) {
    // eslint-disable-next-line no-param-reassign
    message._sender.friendName = placementChatMetadata.facilityName;
  }

  return null;
}
