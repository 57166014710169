import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useHistory } from "react-router-dom";

import { useDefinedWorker } from "../../../Worker/useDefinedWorker";
import { Button } from "../../components/Button";
import { useCreateOrGetPlacementCandidate } from "../../PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { type PlacementWithDetails } from "../../Placements/api/useFetchPaginatedPlacements";
import { getPlacementDetailsPath } from "../../Placements/paths";
import { hasAppliedToPlacement } from "../../Placements/utils";
import { type PlacementChatMetadata } from "../types";

interface PlacementChatHeaderProps {
  channel: SendBird.GroupChannel;
  placement?: PlacementWithDetails;
}

export function PlacementChatHeader(props: PlacementChatHeaderProps) {
  const { channel, placement } = props;
  const history = useHistory();
  const worker = useDefinedWorker();
  const { data: placementCandidate, isLoading: isPlacementCandidateLoading } =
    useCreateOrGetPlacementCandidate(
      { workerId: worker.userId },
      { enabled: isDefined(worker.userId) }
    );
  const placementChatMetadata = channel.getCachedMetaData() as PlacementChatMetadata;

  const hasApplied = hasAppliedToPlacement(placement);

  const eventContext = {
    workplaceId: placement?.workplace.id,
    managingWorkplaceId: placementChatMetadata.managingFacilityId,
    workerId: worker.userId,
    placementId: placement?.id,
    userId: worker.userId,
    userType: "AGENT",
    userEmail: worker.email,
    source: "chatHeader",
  };

  return (
    <Box className="sendbird-custom-chat-header">
      <Stack spacing={5}>
        <Stack spacing={1}>
          <Text variant="h5">{placementChatMetadata?.facilityName}</Text>
          <Text
            variant="body1"
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Job Listing: {placementChatMetadata?.placementTitle}
          </Text>
        </Stack>
        {isDefined(placement) && isDefined(placementCandidate) && (
          <Stack direction="row" spacing={3}>
            {!hasApplied && (
              <Button
                variant="contained"
                size="small"
                disabled={isPlacementCandidateLoading}
                onClick={() => {
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                    ...eventContext,
                    action: "applyButtonClick",
                  });
                  history.push(getPlacementDetailsPath(placement.id, placementCandidate.id));
                }}
              >
                Apply to job
              </Button>
            )}
            <Button
              variant="outlined"
              size="small"
              disabled={isPlacementCandidateLoading}
              href={getPlacementDetailsPath(placement.id, placementCandidate.id)}
              onClick={() => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_CHAT_ACTION, {
                  ...eventContext,
                  action: "viewListingButtonClick",
                });
                history.push(getPlacementDetailsPath(placement.id, placementCandidate.id));
              }}
            >
              View listing
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
