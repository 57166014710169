// reference: https://github.com/orgs/react-hook-form/discussions/1991#discussioncomment-6318535
export function getDirtyFormValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  return Object.keys(dirtyFields).reduce((previous, key) => {
    if (!dirtyFields[key]) {
      return previous;
    }

    return {
      ...previous,
      [key]:
        typeof dirtyFields[key] === "object"
          ? getDirtyFormValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    };
  }, {});
}
