import { isDefined } from "@clipboard-health/util-ts";
import { startOfMonth } from "date-fns";
import { z } from "zod";

import { type Address, type Location } from "../../lib/GoogleMaps";

export enum PlacementCandidateStatus {
  DISABLED = "disabled",
  ONBOARDING = "onboarding",
  PENDING = "pending",
  ACTIVATED = "activated",
}

export enum JobType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  PER_DIEM = "per_diem",
  WEEKEND_WARRIOR = "weekend_warrior",
}

export function isJobType(jobType: string): jobType is JobType {
  return (
    jobType === JobType.FULL_TIME ||
    jobType === JobType.PART_TIME ||
    jobType === JobType.PER_DIEM ||
    jobType === JobType.WEEKEND_WARRIOR
  );
}

export enum ShiftNameType {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export function isShiftNameType(shiftNameType: string): shiftNameType is ShiftNameType {
  return (
    shiftNameType === ShiftNameType.AM ||
    shiftNameType === ShiftNameType.PM ||
    shiftNameType === ShiftNameType.NOC
  );
}

export const placementGeoLocation = z.object({
  coordinates: z.tuple([z.number(), z.number()]),
  type: z.literal("Point"),
});

export type PlacementGeoLocation = z.infer<typeof placementGeoLocation>;

export const PlacementCandidateMarketingLinkClicksSchema = z.object({
  notYetActivated: z.number().optional(),
  notYetSubmittedApplication: z.number().optional(),
});

export type PlacementCandidateMarketingLinkClicks = z.infer<
  typeof PlacementCandidateMarketingLinkClicksSchema
>;

export interface PreferredCandidateArea {
  location: PlacementGeoLocation;
  address: Address;
  distance: number;
}

export interface PreferredCandidateAreaForm {
  address: Address;
  location: Location;
  distance: number;
}

const placementCandidateUploadedResumeStatusSchema = z.enum([
  "no_file",
  "pending",
  "valid",
  "invalid",
]);

export const placementCandidateUploadedResumeSchema = z.object({
  type: z.literal("uploaded-resume"),
  id: z.string(),
  attributes: z.object({
    status: placementCandidateUploadedResumeStatusSchema,
    createdAt: z.coerce.date().optional(),
    updatedAt: z.coerce.date().optional(),
    resumeUrl: z.string().optional(),
    passportUrl: z.string().optional(),
    parsingDecision: z.string().optional(),
    parsingDecisionReason: z.array(z.string()).optional(),
  }),
});

export type PlacementCandidateUploadedResume = z.infer<
  typeof placementCandidateUploadedResumeSchema
>;

const placementCandidatesUploadedResumeSchema = z.object({
  data: z.object({
    type: z.literal("uploaded-resume"),
    id: z.string(),
  }),
});

export interface PlacementCandidate {
  id: string;
  status: PlacementCandidateStatus;
  introductionUrl?: string;
  jobTypesInterested?: JobType[];
  shiftTypesInterested?: ShiftNameType[];
  preferredAreas?: PreferredCandidateArea[];
  worker: {
    id: string;
    name: string;
    qualification: string;
    distance?: number;
    completedShiftsCount?: number;
    cbhPassportDocuments?: string[];
    email?: string;
    phone?: string;
    profileImageUrl?: string;
  };
  marketingLinkClicks?: PlacementCandidateMarketingLinkClicks;
  pendingResume?: PlacementCandidateUploadedResume["attributes"];
  resume?: PlacementCandidateUploadedResume["attributes"];
}

export interface EmptyPlacementCandidate {
  resume: undefined;
}

export const placementCandidateWorkerAttributeIncludedSchema = z.object({
  id: z.string(),
  type: z.literal("workers"),
  attributes: z.object({
    name: z.string(),
    qualification: z.string(),
    distance: z.number().optional(),
    completedShiftsCount: z.number().optional(),
    cbhPassportDocuments: z.array(z.string()).optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    profileImageUrl: z.string().optional(),
  }),
});

export type PlacementCandidateWorkerAttributeIncluded = z.infer<
  typeof placementCandidateWorkerAttributeIncludedSchema
>;

export const placementCandidateSchema = z.object({
  id: z.string(),
  type: z.literal("placement-candidates"),
  attributes: z.object({
    status: z.nativeEnum(PlacementCandidateStatus),
    jobTypesInterested: z.array(z.nativeEnum(JobType)).optional(),
    shiftTypesInterested: z.array(z.nativeEnum(ShiftNameType)).optional(),
    preferredAreas: z
      .array(
        z.object({
          location: z.object({
            type: z.literal("Point"),
            coordinates: z.tuple([z.number(), z.number()]),
          }),
          address: z.object({
            city: z.string().optional(),
            country: z.string().optional(),
            countryCode: z.string().optional(),
            formatted: z.string().optional(),
            line1: z.string().optional(),
            postalCode: z.string().optional(),
            region: z.string().optional(),
            subpremise: z.string().optional(),
            state: z.string().optional(),
            stateCode: z.string().optional(),
            streetName: z.string().optional(),
            streetNumber: z.string().optional(),
            metropolitanStatisticalArea: z.string().optional(),
          }),
          distance: z.number(),
        })
      )
      .optional(),
    introductionUrl: z.string().optional(),
    resumeUrl: z.string().optional(),
    marketingLinkClicks: PlacementCandidateMarketingLinkClicksSchema.optional(),
  }),
  relationships: z.object({
    worker: z.object({
      data: z.object({
        id: z.string(),
        type: z.literal("workers"),
      }),
    }),
    pendingResume: placementCandidatesUploadedResumeSchema.optional(),
    resume: placementCandidatesUploadedResumeSchema.optional(),
  }),
});

export const createCandidateResumeTimespanSchema = z
  .object({
    isCurrent: z.boolean().default(false),
    startDate: z.date().transform((date) => startOfMonth(date)),
    endDate: z
      .date()
      .optional()
      .transform((date) => (date ? startOfMonth(date) : undefined)),
  })
  .superRefine((data, context) => {
    if (!data.isCurrent && !isDefined(data.endDate)) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End date is required",
        path: ["endDate"],
      });
    } else if (!data.isCurrent && isDefined(data.endDate) && data.startDate > data.endDate) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Start date must be before end date",
        path: ["startDate"],
      });
    }
  })
  .transform((data) => {
    if (data.isCurrent) {
      return {
        ...data,
        endDate: undefined,
      };
    }

    return data;
  });

export const createCandidateResumeSchema = z.object({
  experience: z
    .array(
      z.object({
        roleName: z.string().min(1, "Role name is required").max(180, "Role name is too long"),
        workplaceName: z
          .string()
          .min(1, "Workplace name is required")
          .max(300, "Workplace name is too long"),
        timespan: createCandidateResumeTimespanSchema,
      })
    )
    .min(1, "At least one experience entry is required"),
  education: z
    .array(
      z.object({
        institutionName: z
          .string()
          .min(1, "Institution name is required")
          .max(180, "Institution name is too long"),
        educationType: z
          .string()
          .min(1, "Education type is required")
          .max(300, "Education type is too long"),
        timespan: createCandidateResumeTimespanSchema,
      })
    )
    .min(1, "At least one education entry is required"),
});

export type CreateCandidateResumeSchema = z.infer<typeof createCandidateResumeSchema>;
